body {
  background: #f1f5f7;
}

#root {
  height: 100%;
}

.ant-layout {
  height: 100%;
}

.container {
  height: 100%;
  padding: 1em;
}

.ant-input::selection {
  border: 1px solid #334d5b !important;
}

.ant-form-item-label {
  font-weight: bold;
}

.btn-codons, .btn-codons:active, .btn-codons:focus {
  margin: 3px;
}

.codon-info {
  margin: 7px;
}

.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon {
  font-size: 18px;
}

.ant-alert {
  margin-bottom: 30px;
}

.GeneTextArea textarea {
  font-family: 'Lucida Console', monospace;
  letter-spacing: 0.1em;
}

.MutationsTextArea textarea {
  font-family: 'Lucida Console', monospace;
}

.ant-row.Result {
  margin-bottom: 1em;
  height: 100vh;
}

@media (min-width: 1600px) {
  .Result .Result--wrapper {
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
  }
  @media screen {
    .Result .Result--wrapper.Wrapper-Print-Only {
      display: none;
    }
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}

.FeatureViewer {
  background-color: white;
}

.Result--divider {
  margin: 8px 0;
}

.ResultTable {
  font-size: 0.9em;
  background-color: white;
}

.ResultTable td {
  word-break: break-word !important;
}

.ResultTable tr.selected > td {
  background: #fa541c;
  color: white;
}

.ResultTable tr.highlighted.selected > td,
.ResultTable tr.highlighted.selected:hover > td {
  background: #f5222d;
  color: white;
}
.ResultTable tr.highlighted:not(.selected) > td,
.ResultTable tr.highlighted:not(.selected):hover > td {
  background: #ffe58f;
}

.ResultTable .ant-table-thead > tr {
  background: #f1f5f7;
}

.ResultTable .ant-table-thead > tr > th {
  color: #758fce;
  text-align: center;
  word-break: break-word;
}

.ResultTable td.sequence {
  font-size: 12px;
}

.ResultTable tr > td:first-child {
  text-align: center;
}

.ResultTable tr.resultNotFound > td:not(:first-child) {
  text-align: center;
}

.ResultTable tr:not(.highlighted):not(.selected) > td {
  color: gray;
}

.InProgress {
  text-align: center;
}

.hidden {
  display: none;
  visibility: hidden;
}

.ant-layout-header  {
  background-color: #758fce;
}
.ant-layout-header >ul {
  background-color: #758fce;
}
.ant-menu-item {
  color: #f1f5f7;
  font-weight:700px;
}

.ant-menu-item-selected{
  border: none;
}
.ant-menu-horizontal > .ant-menu-item > a:hover{
  color: #f1f5f7;
}
.ant-menu-item-active :hover {
  color: #f1f5f7;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #f1f5f7;
}
.ant-menu-horizontal > .ant-menu-item:hover{
  color: #fff;
  border: none;
}
.HeaderMenu {
  line-height: 63px;
}
.version {
  float: right;
}

.HeaderFont {
  font-size: 18px;
  font-weight: 800;
}

.GeneTextArea .has-success.has-feedback .ant-form-item-children:after {
  content: none;
}

ul{
  display: inline-block;
  vertical-align: top;
  text-align: justify;
}
li{
  list-style: none;
  padding:0px;
}
