
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.ant-input-number {
    width: 100% !important;
}

th.PASFragmentSeqCol {
    text-align: center !important;
}


.PASResultTableCnt {
    height: 100% !important;
    overflow: hidden !important;
}
.ant-row-custom{
    margin-bottom: 10px !important;
}
.ant-input-group-addon{
    width:25% !important
}

.ant-popover{
    left: 20% !important;
    max-width: 30%
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow{
    left: 38% !important;
}
.question-circle{
    color:#269487;
    font-size: 17px !important;
}

.FormActions {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.FormActions .ant-btn + .ant-btn {
  margin-left: 5px;
}

.verticalRadio {
    display: block;
    height: 30px;
    lineHeight: 30px;
}
