.loader {
    border: 5px solid #fff;
    border-radius: 50%;
    border-top: 5px solid #0c877c;
    border-bottom: 5px solid #0c877c;
    width: 80px;
    height: 80px;
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .info{
    color: red;
  }
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
